<template>
    <div>
        <a-spin :spinning="loading">
            <span @click="onShowModal">
                <slot>
                    <a-button type="primary">选择场次</a-button>
					<span> 已选{{selectedRowKeys.length}}场次</span>
                </slot>
            </span>
            <a-modal v-model:visible="visible" width="800px" title="选择场次" @cancel="onCancel" @ok="onOk">
				<a-spin :spinning="loading">
					<div class="ui-labelModal__list ">
                <a-form ref="formRef" :model="modelRef" @finish="onSearch">
                    <a-row>
                        <a-form-item class="ui-form-item" label="放映时间">
                            <a-range-picker show-time v-model:value="time" />
                        </a-form-item>
                    </a-row>
                    <a-row>
                        <a-col :span="24" style="text-align: right;">
                            <a-button style="margin-right: 20px;" type="primary" html-type="submit">搜索</a-button>
                            <a-button @click="resetBtn">重置</a-button>
                        </a-col>
                    </a-row>
                </a-form>
                <div style="margin-top: 10px;">
                    <a-alert type="info" show-icon>
                        <template #message>
                            <div>已选择{{ selectedRowKeys.length }}项</div>
                        </template>
                        <template #closeText>
                            <a-button size="small" @click.stop="onClear">清空</a-button>
                        </template>
                    </a-alert>
                </div>
                <a-table
                        style="margin-top: 10px;"
                        rowKey="id"
                        :dataSource="dataList"
                        :pagination="pagination"
                        :columns="columns"
                        :rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
                        bordered
                        size="middle"
                >
                   <template #bodyCell="{ column, record }">
					   <template v-if="column.key === 'time'">
						   {{ transDateTime(record.showDateTime) }}
					   </template>
				   </template>
                </a-table>
				</div>
				</a-spin>
            </a-modal>
        </a-spin>
    </div>
</template>

<script>
	import { getScheduleList } from '@/service/modules/cinema.js';
    export default {
        model: {
            prop: 'value'
        },
        props: {
            value: {
                type: Array,
                default: () => {
                    return []
                }
            },
			// selectItem: {
			// 	type: Array,
			// 	default: () => {
			// 	    return []
			// 	}
			// },
            type: {     // checkbox or radio
                type: String,
                default: 'checkbox'
            },
			id: {	// 影院ID
				type: null,
				default: undefined
			}
        },
		watch: {
			// selectItem(newVal) {
			// 	this.selectedListData = JSON.parse(JSON.stringify(newVal));
			// },
			id(newVal) {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.getData();
			},
		},
        created() {
            this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
        },
		mounted() {
		},
        data() {
            return {
                loading: false,
                visible: false,
                selectedRowKeys: [],    // 选择的id集合
                selectedListData: [],   // 选择的数据集合
                selectedListIdDataMap: {},  // 每选中一项时的数据对比字典，防止分页后数据丢失
                classifyList: [],
				dataList: [],
                columns: [{
                    title: '影片名称',
                    dataIndex: 'filmName'
                }, {
                    title: '上映时间',
					key: 'time'
                }, {
                    title: '影厅名',
					dataIndex: 'hallName'
                }, {
					title: '语音',
					dataIndex: 'language'
				},{
                    title: '版本',
					dataIndex: 'dimensional'
                }],
				time: [],
				modelRef: {
				},
				searchData: {},
                 pagination: {
                    showSizeChanger: true,
                    showQuickJumper: true,
                    size: "middle",
                    showTotal: (total) => {
                      return "共 " + total + " 条";
                    },
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    onChange: (page) => {
                      this.pagination.current = page;
                      this.getData();
                    },
                    onShowSizeChange: (page, pageSize) => {
                      this.pagination.current = 1;
                      this.pagination.pageSize = pageSize;
                      this.getData();
                    }
                },
            }
        },
        methods: {
            onSearch() {
                this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.modelRef));
				if (this.time && this.time.length) {
					this.searchData.startTime = parseInt(this.time[0].valueOf() / 1000);
					this.searchData.endTime = parseInt(this.time[1].valueOf() / 1000);
				}
                this.getData();
            },
            async getData() {
				this.loading = true;
				try {
					let ret = await getScheduleList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						cinemaId: this.id,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.dataList = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
            },
            onShowModal() {
				if (!this.id) {
					return this.$message.warn('请先选择所属影院');
				}
				this.getData();
                this.visible = true;
                this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
            },
            onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						// this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						// this.selectedListData = this.selectedListData.filter(item => {
						// 	return item.id !== record.id;
						// });
					}
				} else {
					if (selected) {
						// this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						// this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
            },
			onSelectAll(selected) {
				if (selected) {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							// this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							// this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
            resetBtn() {
				this.time = [];
              this.$refs.formRef.resetFields();
              this.onSearch();
            },
            onClear() {
                this.$confirm({
					title: '提示',
					content: '是否清除选择？',
					onOk: () => {
						this.selectedRowKeys = [];
						this.selectedListData = [];
						this.selectedListIdDataMap = [];
					}
				})
            },
            onCancel() {
                this.visible = false;
                this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
            },
            onOk() {
                this.$emit('update:value', this.selectedRowKeys);
                // this.$emit('change', this.selectedListData);
                this.visible = false;
            }
        }
    }
</script>

<style scoped>
.ui-form-item {
    margin-right: 20px;
}
.ui-labelModal__list {
	height: 600px;
	overflow: auto;
}
</style>